.root :global(.ant-form-item-control-input-content >input.ant-input) {
    line-height: 30px;
}

.root :global(.ant-input-affix-wrapper) {
    border-radius: 3px;
    border: 1px solid var(--bcn-color-gray-002);
}
.root :global(.ant-input-affix-wrapper .ant-input-prefix) {
    margin-inline-end: 8px;
}

.root :global(.ant-input-affix-wrapper >input.ant-input) {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: var(--bcn-color-black-001);
}
.root :global(.ant-input-affix-wrapper >input.ant-input::placeholder) {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: var(--bcn-color-black-003);
}

.root :global(.ant-input-group-wrapper-outlined .ant-input-group-addon) {
    background-color: transparent;
    padding-left: 10px;
    padding-right: 0;
}
.root :global(.ant-input-group >.ant-input:last-child) {
    border-left: 0;
    padding-left: 2px;
}

.root:global(.ant-form-item .ant-form-item-label >label) {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: var(--bcn-color-black-002);
    height: unset;
    margin-bottom: 0;
}

.wrap_root :global(.ant-form-item-row) {
    display: grid;
    gap: 8px;
}
.wrap_root :global(.ant-form-item-row .ant-form-item-label) {
    width: 100%;
    max-width: unset;
    text-align: left;
}
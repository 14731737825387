.root:global(.ant-btn) {
    /* line-height: 40px;
    height: 40px; */
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}

.root:global(.ant-btn-primary),
.root:global(.ant-btn-default) {
    line-height: 40px;
    height: 40px;
}
.root:global(.ant-btn-primary) {
    background-color: var(--bcn-color-blue-001);
    font-weight: 700;
}

.root:global(.ant-btn-primary.ant-btn-disabled),
.root:global(.ant-btn-primary:disabled) {
    background-color: rgba(0,0,0,0.04);
}

.root:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover) {
    background-color: var(--bcn-color-blue-002);
}

.root:global(.ant-btn-link) {
    padding: 0;
    color: var(--bcn-color-blue-001);
}
.root:global(.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover) {
    color: var(--bcn-color-blue-002);
}

.root:global(.ant-btn-default) {
    background: var(--bcn-color-white);
    border-color: var(--bcn-color-gray-002);
    color: var(--bcn-color-black-001);
    /* color: var(--bcn-color-blue-001); */
    width: 100%;
    font-weight: 700;
}
.root:global(.ant-btn-default:disabled) {
    border-color: var(--bcn-color-gray-004);
    color: var(--bcn-color-black-003);
    background: var(--bcn-color-gray-004);
}

.root:global(.ant-btn-text) {
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: var(--bcn-color-black-007);
}
.root:global(.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover) {
    background-color: transparent;
    color: var(--bcn-color-black-007);
}
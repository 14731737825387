.root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: var(--bcn-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel {
    display: grid;
    grid-template-columns: minmax(37%, 526px) minmax(63%, 914px);
    box-sizing: border-box;
    max-width: 100%;
    width: 1440px;
    background-color: var(--bcn-color-gray-001);
}

.panel_left {
    padding: 0px 40px;
    display: grid;
    gap: 42px;
    height: 100vh;
    align-items: center;
    align-content: center;
}

.panel_right {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: var(--bcn-color-white);
}

.right_box {
    display: grid;
    align-content: space-between;
    width: 400px;
}

.right_box h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: var(--bcn-color-black-001);
    margin-bottom: 8px;
}

.right_top_create {
    display: flex;
    gap: 8px;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-002);
}

.right_top_create p {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-001);
    cursor: pointer;
}

.form {
    margin-top: 48px;
}

.form_other {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.right_bottom {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-003);
}

@media screen and (max-width: 768px) {
    .root {
        height: unset;
        overflow-y: auto;
    }

    .panel {
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        border: none;
    }

    .panel_left {
        height: unset;
        justify-items: center;
    }

    .panel_right {
        align-items: unset;
        padding-bottom: unset;
        padding-top: 24px;
    }
}
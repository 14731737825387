.root :global(.ant-checkbox-wrapper) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-001);
}

.root :global(.ant-checkbox .ant-checkbox-inner) {
    border-radius: 3px;
    border: 1px solid var(--bcn-color-gray-002);
}

.root :global(.ant-checkbox:hover .ant-checkbox-inner) {
    border: 1px solid var(--bcn-color-blue-002);
}

.root :global(.ant-checkbox-checked .ant-checkbox-inner) {
    border-color: var(--bcn-color-blue-001);
    background-color: var(--bcn-color-blue-001);
}

.root :global(.ant-checkbox-checked.ant-checkbox-disabled  .ant-checkbox-inner) {
    opacity: 0.6;
}

.root :global(.ant-checkbox-checked.ant-checkbox-disabled  .ant-checkbox-inner:after) {
    border-color: #ffffff;
}

.root :global(.ant-checkbox-checked:hover .ant-checkbox-inner) {
    border-color: var(--bcn-color-blue-002);
    background-color: var(--bcn-color-blue-002);
}
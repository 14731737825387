.form_other {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    justify-content: center;
}

h1.title {
    color: var(--bcn-color-blue-001);
    text-align: center;
}

.right_top_create {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-002);
    text-align: center;
    margin-bottom: 32px;
}

.icon {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}